.App {
    text-align: center;
    .header {
        background: url(https://cdn.sandals.com/sandals/v12/images/general/butch-live-memorial/header.jpg) no-repeat top/cover;
        width: 100%;
        height: 80vw;
        padding-top: 55px;
        @media (min-width: 768px) {
            padding-top: 100px;
        }

        .celebrating {
            margin: 0;
            font-size: 12px;
            color: white;
            text-transform: uppercase;
            @media (min-width: 768px) {
                font-size: 15px;
            }
        }
        .butch {
            margin: 0;
            font-size: 23px;
            font-weight: 300;
            color: white;
            text-transform: uppercase;
            @media (min-width: 425px) {
                font-size: 30px;
            }
            @media (min-width: 768px) {
                font-size: 50px;
            }
        }
        .date {
            margin: 0;
            font-size: 12px;
            color: #a89255;
            text-transform: uppercase;
            @media (min-width: 768px) {
                font-size: 20px;
            }
        }
    }
    .content {
        background: black;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        
        .video {
            position: relative;
            display: block;
            width: 82%;
            height: 80%;
            margin: -10% auto 0;
            @media (min-width: 768px) {
                width: 660px;
                height: 400px;
            }
            @media (min-width: 1024px) {
                width: 720px;
                height: 465px;
            }
    
            &::before {
                content: "";
                position: absolute;
                width: 110%;
                height: 90%;
                background: rgba(255, 255, 255, 0.1);
                left: -5%;
                top: -10%;
                z-index: 0;
            }
            .image-container {
                position: relative;
                width: 100%;
                &:hover {
                    cursor: pointer;
                }
                .thumbnail {
                    width: 100%;
                }
                .play-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .video-player {
                position: relative;
                display: none;

                &.show {
                    display: block;
                }
            }
        }
        .text-container {
            max-width: 85%;
            margin: 0 auto;
            @media (min-width: 768px) {
                max-width: 640px;
            }

            .outpouring-love {
                max-width: 520px;
                margin: 70px auto 20px;
                color: white;
                font-size: 20px;
                line-height: 1.8;
                font-weight: 200;
                @media (min-width: 768px) {
                    margin: 70px auto 20px;
                    font-size: 24px;
                }
            }
            .share-memories {
                max-width: 250px;
                margin: 25px auto;
                font-size: 14px;
                line-height: 1.8;
                color: #757575;
                border: 1px solid #757575;
                padding: 20px 7px;
                @media (min-width: 375px) {
                    max-width: 300px;
                    padding: 20px 7%;
                }
                @media (min-width: 768px) {
                    padding: 20px 110px;
                }

                a {
                    font-weight: 700;
                    color: white;
                    &:hover {
                        color: #a89255;
                    }
                }
            }
            .lieu-flowers {
                margin-bottom: 70px;
                font-size: 14px;
                line-height: 1.8;
                color: #757575;
                max-width: 530px;

                a {
                    color: white;
                    text-decoration: none;
                    font-weight: 600;
                    &:hover {
                        color: #a89255;
                    }
                }
            }
        }
        .logos {
            max-width: 80%;
        }
    }
}
